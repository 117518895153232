import revive_payload_client_mBFtetk4Ug from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_klIcaKRJ0F from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mRzMFcfJYP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_epd6KhbEcu from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ws15Fo0nmJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TQLkbbhQMs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IliJcBSqn8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qQ4TgAIn2h from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ZRdxoYAtkz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@2.79.2_sass@1.80.4_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_RUtGnMtaoE from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@5.4.10_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Thxfwi8kIY from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@5.4.10_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_mqQQeyf3x2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@2.79.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import slideovers_FZ8QhDCrlU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_qrcode@1.5.4_rollup@2.79.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_ZFO178nzgd from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_qrcode@1.5.4_rollup@2.79.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_XiHzQCamyB from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_qrcode@1.5.4_rollup@2.79.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_BgDihNaN7u from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_pe01tadUrF from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.6.1_rollup@2.79.2_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import plugin_client_dWN2pmnRI6 from "/opt/buildhome/repo/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@2.79.2_vue@3.5.12/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_wofILYXtuU from "/opt/buildhome/repo/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_TFCEIBHo6D from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.4_rollup@2.79.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_YJGsTbn2VI from "/opt/buildhome/repo/plugins/calendar.client.js";
import content_GArnI6Y6ho from "/opt/buildhome/repo/plugins/content.js";
import tracker_client_GsGCjPbvL8 from "/opt/buildhome/repo/plugins/tracker.client.js";
import vconsole_client_Zu3Hk7iamm from "/opt/buildhome/repo/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "/opt/buildhome/repo/plugins/wallet.client.js";
export default [
  revive_payload_client_mBFtetk4Ug,
  unhead_klIcaKRJ0F,
  router_mRzMFcfJYP,
  payload_client_epd6KhbEcu,
  navigation_repaint_client_Ws15Fo0nmJ,
  check_outdated_build_client_TQLkbbhQMs,
  chunk_reload_client_IliJcBSqn8,
  plugin_vue3_qQ4TgAIn2h,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZRdxoYAtkz,
  pwa_icons_RUtGnMtaoE,
  pwa_client_Thxfwi8kIY,
  nuxt_plugin_mqQQeyf3x2,
  slideovers_FZ8QhDCrlU,
  modals_ZFO178nzgd,
  colors_XiHzQCamyB,
  plugin_client_BgDihNaN7u,
  plugin_pe01tadUrF,
  floating_vue_EIcJ7xiw0h,
  plugin_client_dWN2pmnRI6,
  plugin_wofILYXtuU,
  plugin_TFCEIBHo6D,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  tracker_client_GsGCjPbvL8,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]